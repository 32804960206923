<template>
  <div class=" w-full">
    <Wrapper class="max-w-xl">
        <div v-if="type === 'registration'" class="w-4/5 mx-auto">
            <h2 class="mb-3 text-2xl text-blue-dark"><b>Su inconformidad fue registrada</b></h2>
            <p class="mb-5 text-sm">El código asignado para hacerle seguimiento es:</p>
            <h2 class="mb-5 text-3xl text-blue-dark"><b>{{complaintCode}}</b></h2>
        </div>
        <div v-if="type == 1 || type == 2" class="w-4/5 mx-auto">
          <h2 v-if="type == 1 " class="mb-3 text-2xl text-blue-dark"><b>La réplica fue registrada</b></h2>
          <h2 v-if="type == 2 " class="mb-3 text-2xl text-blue-dark"><b>Su queja fue presentada al Defensor del Consumidor Financiero</b></h2>
          <p class="my-5 text-sm">El código asignado para el seguimiento de su réplica es:</p>
          <h2 class="mb-5 text-3xl text-blue-dark"><b>{{complaintCode}}</b></h2>
        </div>
        <div v-if="type === 'close' " class="w-4/5 mx-auto">
          <h2 class="mb-3 text-2xl text-blue-dark"><b>Su queja se ha cancelado de manera exitosa</b></h2>
          <p class="my-5 text-sm">
            La interposición de queja con el número radicado no. <b>{{complaintCode}}</b> ha sido retirada exitosamente de nuestra plataforma Smart Supervision.
            <br><br>
            En caso de querer realizar una nueva queja, seleccione la opción de interponer nueva queja en nuestra bandeja de inicio.
          </p>
        </div>
        <div v-if="type === 'jurisdictional_registration'" class="w-4/5 mx-auto">
            <h2 class="mb-3 text-2xl text-blue-dark"><b>Su inconformidad fue registrada </b><b v-if="complaintCode">con el código:</b></h2>
            <div v-if="complaintCode">
              <h2 class="mb-5 text-3xl text-blue-dark bg-gray-100"><b>{{complaintCode}}</b></h2>
              <p class="mb-5 text-sm">Con este número usted podrá hacer seguimiento de su <b>queja.</b></p>
            </div>
            <div v-if="isDemandOrConciliation">
              <p class="my-5 text-sm">
                En los próximos días recibirá una comunicación informando el número de radicación (10 dígitos) que identificará su trámite hasta la finalización.
                <br><br>
                Para el <b>seguimiento de su demanda</b> de acción de protección podrá consultar su expediente con el número de diez dígitos informado, ingresando <a href="https://www.superfinanciera.gov.co/jsp/10105184" target="_blank" class="text-blue-500">aquí</a>.
                <br><br>
                Para el <b>seguimiento de su solicitud de conciliación</b> podrá realizarlo a través de la línea telefónica 3078042 o en el Punto al Ciudadano.
              </p>
            </div>
            <p class="mb-5 text-sm">Fecha de registro: <b class="text-blue-800">{{ currentDate }}</b></p>
        </div>
        <div  class="w-1/2 mx-auto">
         <a-button @click="rated ? $router.push('/customer') : $router.push(`${'/customer/complaint/calification/'+complaintCode}`)" type="primary" shape="round" :block="true"  >
              ACEPTAR
        </a-button>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  computed:{
    type: function(){
      return this.$route.query.type || ''
    },
    complaintCode: function(){
      return this.$route.query.complaintCode || ''
    },
    rated: function(){
      return this.$route.query.rated==true ? true : false
    },
    currentDate: function(){
      return moment().format('DD/MM/YYYY');
    },
    isDemandOrConciliation: function(){
      return this.$route.query.isDemandOrConciliation==true ? true : false;
    },
  }
}
</script>

<style>

</style>